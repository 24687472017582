<template>

<app-content class="logout">

	

</app-content>
	
</template>

<script>

export default {

	created: function() {

		this.$store.commit('token', null)
		this.$store.commit('authorised', false)

		this.$router.push({
			name: 'Login'
		})

	}

}

</script>

<style scoped>

</style>